<template>
    <v-data-table
        :loading="loading"
        loading-text="Chargement..."
        :headers="headers"
        :items="[...gear.using, ...gear.old]"
        :items-per-page="20"
        locale="fr-CH"
        no-data-text="Aucun résultat"
        no-results-text="Aucun résultat correspondant aux filtres"
        :header-props="{
            'sort-by-text': 'Trier par',
        }"
        :footer-props="{
            'items-per-page-all-text': 'Tous',
            'items-per-page-text': 'Lignes par page',
            'items-per-page-options': [5, 10, 20, 50, -1],
        }"
    >

        <template #[`item.type`]="{item, value}">
            <v-tooltip 
                v-if="warning[value] && item.using"
                bottom
                color="warning"
            >
                <template #activator="{on, attrs}">
                    <v-icon
                        v-on="on"
                        v-bind="attrs"
                        color="warning"
                    >
                        mdi-alert
                    </v-icon>
                </template>
                <span>Plusieurs items du même type en usage</span>
            </v-tooltip>

            {{ getGearType(value).name }}
        </template>

        <template #[`item.model`]="{item}">
            {{ getGearModel(item.type, item.model).name }}
        </template>

        <template #[`item.variant`]="{item}" >
            {{ getGearVariant(item.type, item.variant).name }}
        </template>

        <template #[`item.using`]="{value}">
            <v-icon>{{ value ? 'mdi-check' : 'mdi-close' }}</v-icon>
        </template>

        <!-- <template #[`item.lastDate`]="{item, value}" >
                <v-icon class="mr-1">{{ item.using ? 'mdi-home-export-outline' : 'mdi-home-import-outline' }}</v-icon>
                {{ new Date(value).toLocaleDateString('fr-CH') }}
        </template> -->

        <template #[`item.id`]="{item}">
            <m-gear-details-view-dialog :gearItem="item" />
            <m-gear-details-edit-dialog v-if="canEdit" type="switch" icon :disabled="!item.using"
            :gearItem="item" :otherItems="gear" :uid="uid" />
            <m-gear-details-edit-dialog v-if="canEdit" type="edit" icon
            :gearItem="item" :otherItems="gear" :uid="uid" />
        </template>

    </v-data-table>
</template>

<script>
import { GEAR_TYPES } from '@/constants/gear';
import mGearDetailsEditDialog from './editDialog.vue';
import mGearDetailsViewDialog from './viewDialog.vue';

export default {
    name: 'mGearDataTable',
    components: { mGearDetailsEditDialog, mGearDetailsViewDialog },
    props: {
        loading: {type: [Boolean, String], default: 'accent'},
        gear: {type: Object, required: true},
        warning: {type: Object, default: () => ({})},
        usingFilter: {type: [Boolean, null], default: null},
        uid: {type: [String], default: ''},
    },
    data: () => ({
    }),
    computed: {
        headers() {
            return [
                { text: 'Type', value: 'type' },
                { text: 'Modèle', value: 'model' },
                { text: 'Taille', value: 'size' },
                { text: 'Variante', value: 'variant' },
                { text: 'En usage', value: 'using', 
                    filter: value => {
                        if (this.usingFilter == null)
                            return true;
                        return this.usingFilter == value;
                    } 
                },
                // { text: 'Date', value: 'lastDate' },
                { text: 'Remarques', value: 'remarks', },
                { text: 'Actions', value: 'id', align: 'center' },
            ];
        },
        canEdit() {
            return this.$store.getters.canAccess('gear_admin', 'gear_mod');
        }
    },
    methods: {
        /**
         * Get model name from type model
         * @param {string} type Type of the gear
         * @returns {string} The name of the type
         */
        getGearType(type) {
            return GEAR_TYPES[type] || type;
        },
        /**
         * Get model name from type model
         * @param {string} type Type of the gear
         * @param {string} model Model of gear type
         * @returns {string} The name of the model
         */
        getGearModel(type, model) {
            return GEAR_TYPES[type]?.models[model] || {name: model || ''};
        },
        getGearVariant(type, variant) {
            const def = {name: variant || ''};
            return GEAR_TYPES[type].hasVariants ? (GEAR_TYPES[type]?.variants[variant] ?? def) : def;
        },
    },
}
</script>

<style>
.table-btn {
    text-decoration: none;
}
.table-btn:hover {
    text-decoration: underline;
}
</style>