<template>
    <v-dialog
        v-model="dialog"
        max-width="500px"
        @input="dialogToggled"
    >

        <template #activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
        </template>

        <v-card>
            <v-card-title>
                Voir item
                <v-spacer />
                <v-btn
                    icon
                    large
                    @click="dialog = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <v-row>
                    <v-col cols="6" :sm="3">
                        <span class="font-weight-bold">Type</span>
                        <br>
                        {{ getType }}
                    </v-col>
                    <v-col cols="6" :sm="3">
                        <span class="font-weight-bold">Modèle</span>
                        <br>
                        {{ getModel }}
                    </v-col>
                    <v-col cols="6" :sm="3">
                        <span class="font-weight-bold">Variante</span>
                        <br>
                        {{ getVariant || 'n/a' }}
                    </v-col>
                    <v-col cols="6" :sm="3">
                        <span class="font-weight-bold">Taille</span>
                        <br>
                        {{ gearItem.size || 'n/a' }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <span class="font-weight-bold">Remarques</span>
                        <br>
                        {{ gearItem.remarks || 'n/a' }}
                    </v-col>
                    <v-col cols="12">
                        <span class="font-weight-bold">Identifiant ECA</span>
                        <br>
                        {{ gearItem.ecaid || 'n/a' }}
                    </v-col>

                    <v-col cols="12">
                        <span class="font-weight-bold">Distribution </span>
                        <v-icon small >mdi-home-export-outline</v-icon>
                        <br>
                        Le 
                        {{ new Date(gearItem.outDate).toLocaleDateString('fr-CH') }}
                        par
                        <span v-if="outUser">{{ outUser }}</span>
                        <v-progress-circular v-else 
                            size="16" width="2" color="accent" indeterminate class="ml-1"
                        />
                    </v-col>

                    <v-col cols="12" v-if="!gearItem.using">
                        <span class="font-weight-bold">Récupération </span>
                        <v-icon small >mdi-home-import-outline</v-icon>
                        <br>
                        Le 
                        {{ new Date(gearItem.inDate).toLocaleDateString('fr-CH') }}
                        par
                        <span v-if="inUser">{{ inUser }}</span>
                        <v-progress-circular v-else 
                            size="16" width="2" color="accent" indeterminate class="ml-1"
                        />
                    </v-col>
                    
                    <v-col cols="12" v-else>
                        <span class="font-weight-bold">Récupération </span>
                        <v-icon small >mdi-home-import-outline</v-icon>
                        <br>
                        <span class="font-italic">L'item est en usage</span>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { GEAR_TYPES } from '@/constants/gear';

import { doc, getDoc, getFirestore } from "firebase/firestore";
const db = getFirestore();

export default {
    name: 'mGearDetailsViewDialog',
    props: {
        gearItem: { type: Object, required: true }
    },
    data() { return {
        dialog: false,
        loading: false,
        inUser: '',
        outUser: '',
    }},
    computed: {
        getType() {
            return GEAR_TYPES[this.gearItem.type].name || '';
        },
        getModel() {
            return GEAR_TYPES[this.gearItem.type]?.models[this.gearItem.model]?.name || '';
        },
        getVariant() {
            const type = this.gearItem.type;
            const variant = this.gearItem.variant;
            const def = variant ?? '';
            return GEAR_TYPES[type].hasVariants ? (GEAR_TYPES[type]?.variants[variant]?.name ?? def) : def;
        },
    },
    mounted() {
    },
    methods: {
        dialogToggled(e) {
            if (e) {
                const keys = ['outUser', 'inUser'];
                keys.forEach(key => {
                    this[key] = '';
                    if (this.gearItem[key]) {
                        getDoc(doc(db, 'users', this.gearItem[key])).then(user => {
                            if (user.exists()) {
                                this[key] = `${user.get('name.last')} ${user.get('name.first').charAt(0)}.`;
                            }
                        });
                    }
                });
            }
        },
    }
}
</script>

<style>

</style>