<template>
    <v-container>
        <v-row justify="space-between">
            <v-col cols="12" sm="6" md="4"
            class="pt-3 pb-0 pb-sm-3">
                <v-text-field
                    label="Rechercher"
                    v-model="search"
                    hint="Chercher par type ou modèle"
                    clearable
                    prepend-icon="mdi-magnify"
                />
            </v-col>
            <v-col cols="12" sm="6" md="4"
            class="pt-3 pb-0 pb-sm-3">
                <v-select
                    label="Type"
                    v-model="filterType"
                    :items="getTypesArr"
                    item-text="name"
                    item-value="value"
                    clearable
                    prepend-icon="mdi-filter"
                />
            </v-col>
        </v-row>

        <v-row class="mb-1 mt-0" align="end">
            <v-col>
                <p class="my-0 py-0 text-caption">
                    <v-icon>mdi-information-outline</v-icon>
                    Cliquez sur un stock pour le modifier
                </p>
            </v-col>
            <v-col cols="auto">
                <m-gear-stock-edit-dialog/>
            </v-col>
        </v-row>

        <v-data-table
            :loading="loading"
            loading-text="Chargement..."
            :headers="headers"
            :items="items"
            :sort-by="['type', 'model', 'size']"
            multi-sort
            :items-per-page="20"
            :search="search"
            :custom-filter="customFilter"
            locale="fr-CH"
            no-data-text="Aucun résultat"
            no-results-text="Aucun résultat correspondant aux filtres"
            :header-props="{
                'sort-by-text': 'Trier par',
            }"
            :footer-props="{
                'items-per-page-all-text': 'Tous',
                'items-per-page-text': 'Lignes par page',
                'items-per-page-options': [5, 10, 20, 50, -1],
            }"
        >

        <template #[`item.type`]="{value}">
            {{ getGearType(value).name }}
        </template>
        <template #[`item.model`]="{item}">
            {{ getGearModel(item.type, item.model).name }}
        </template>
        <template #[`item.variant`]="{item}" >
            {{ getGearVariant(item.type, item.variant).name }}
        </template>
        <template #[`item.stockCully`]="{item, value}" >
            <v-edit-dialog
                transition="slide-x-transition"
                @close="editValue(item)"
            >
                {{ value || 0 }}

                <template #input>
                    <v-text-field
                        v-model="item.stockCully"
                        label="Stock Cully"
                        type="number"
                        :rules="[rules.posNum]"
                        single-line
                    />
                </template>
            </v-edit-dialog>
        </template>

        <template #[`item.stockForel`]="{item, value}" >
            <v-edit-dialog
                transition="slide-x-transition"
                @close="editValue(item)"
            >
                {{ value || 0 }}

                <template #input>
                    <v-text-field
                        v-model="item.stockForel"
                        label="Stock Forel"
                        type="number"
                        :rules="[rules.posNum]"
                        single-line
                    />
                </template>
            </v-edit-dialog>
        </template>

        <template #[`item.id`]="{value}">
            <m-confirm-action 
                btnIconDisp
                @confirmed="deleteItem(value)"
            />
        </template>

        </v-data-table>
    </v-container>
</template>


<script>
import mGearStockEditDialog from '../components/gearStock/editDialog.vue';
import mConfirmAction from '../components/app/confirmAction.vue';
import { GEAR_TYPES } from '@/constants/gear';
import { collection, deleteDoc, doc, getFirestore, onSnapshot, updateDoc } from "firebase/firestore";
const fs = getFirestore();
let dbUnsub;

export default {
    name: 'mGearStock',
    components: {
        mGearStockEditDialog,
        mConfirmAction,
    },
    data() { return { 
        items: [],
        loading: 'accent',
        search: '',
        filterType: '',
        rules: {
            posNum: value => !value || (!isNaN(value) && value >= 0) || 'Doit être un chiffre positif',
        },
    }},
    computed: {
        headers() {
            const h = [
                { text: 'Type', value: 'type', filter: val => !this.filterType || val === this.filterType },
                { text: 'Modèle', value: 'model' },
                { text: 'Variante', value: 'variant' },
                { text: 'Taille', value: 'size' },
                { text: 'Cully', value: 'stockCully' },
                { text: 'Forel', value: 'stockForel' },
            ];
            if (this.$store.getters.canAccess('gear_admin'))
                h.push({text: 'Actions', value: 'id'});
            return h;
        },
        getTypesArr() {
            return Object.values(GEAR_TYPES)
        },
    },
    methods: {
        customFilter(value, search, item) {
            value;
            return !search 
                || this.getGearType(item.type).name.toLowerCase().includes(search.toLowerCase())
                || this.getGearModel(item.model).name.toLowerCase().includes(search.toLowerCase());
        },
        getGearType(type) {
            return GEAR_TYPES[type] || type;
        },
        getGearModel(type, model) {
            return GEAR_TYPES[type]?.models[model] || {name: model || ''};
        },
        getGearVariant(type, variant) {
            const def = {name: variant ?? ''};
            return GEAR_TYPES[type].hasVariants ? (GEAR_TYPES[type]?.variants[variant] ?? def) : def;
        },
        editValue(item) {
            updateDoc(doc(fs, 'gear-stock', item.id), {
                stockCully: Number(item.stockCully) || 0,
                stockForel: Number(item.stockForel) || 0,
            }).then(() => {
                this.$store.commit('setSnackbar', { text: 'Item changé', color: 'success', icon: 'mdi-check' });
            }).catch(error => {
                this.$store.commit('setError', { type: 'firebase-firestore', code: error.code, message: error.message });
            });
        },
        deleteItem(id) {
            deleteDoc(doc(fs, 'gear-stock', id)).then(() => {
                this.$store.commit('setSnackbar', { text: 'Item supprimé', color: 'success', icon: 'mdi-check' });
            }).catch(error => {
                this.$store.commit('setError', { type: 'firebase-firestore', code: error.code, message: error.message });
            });
        },
    },
    mounted() {
        dbUnsub = onSnapshot(collection(fs, 'gear-stock'), snap => {
            this.loading = 'accent';
            let dbItems = [];
            snap.forEach(doc => {
                dbItems.push(Object.assign(doc.data(), {id: doc.id}));
            });
            this.items = dbItems;
            this.loading = false;
        });
    },
    destroyed() {
        if (dbUnsub)
            dbUnsub();
    }
}
</script>