var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mb-2",attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"pt-3 pb-0 pb-sm-3",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Rechercher","hint":"Chercher par nom ou NIP","clearable":"","prepend-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"pt-3 pb-0 pb-sm-3",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-select',{attrs:{"label":"Détachement","items":['DAP', 'DPS'],"clearable":"","prepend-icon":"mdi-filter"},model:{value:(_vm.filterSection),callback:function ($$v) {_vm.filterSection=$$v},expression:"filterSection"}})],1)],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"loading-text":"Chargement...","headers":_vm.headers,"items":_vm.users,"sort-by":"shortName","items-per-page":20,"search":_vm.search,"custom-filter":_vm.customFilter,"locale":"fr-CH","no-data-text":"Aucun résultat","no-results-text":"Aucun résultat correspondant aux filtres","header-props":{
            'sort-by-text': 'Trier par',
        },"footer-props":{
            'items-per-page-all-text': 'Tous',
            'items-per-page-text': 'Lignes par page',
            'items-per-page-options': [5, 10, 20, 50, -1],
        }},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.rank",fn:function(ref){
        var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getRank(value)['med'])+". ")]}},{key:"item.gear.hood",fn:function(ref){
        var value = ref.value;
return [(value)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }