var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"pt-3 pb-0 pb-sm-3",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Rechercher","hint":"Chercher par type ou modèle","clearable":"","prepend-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"pt-3 pb-0 pb-sm-3",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-select',{attrs:{"label":"Type","items":_vm.getTypesArr,"item-text":"name","item-value":"value","clearable":"","prepend-icon":"mdi-filter"},model:{value:(_vm.filterType),callback:function ($$v) {_vm.filterType=$$v},expression:"filterType"}})],1)],1),_c('v-row',{staticClass:"mb-1 mt-0",attrs:{"align":"end"}},[_c('v-col',[_c('p',{staticClass:"my-0 py-0 text-caption"},[_c('v-icon',[_vm._v("mdi-information-outline")]),_vm._v(" Cliquez sur un stock pour le modifier ")],1)]),_c('v-col',{attrs:{"cols":"auto"}},[_c('m-gear-stock-edit-dialog')],1)],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"loading-text":"Chargement...","headers":_vm.headers,"items":_vm.items,"sort-by":['type', 'model', 'size'],"multi-sort":"","items-per-page":20,"search":_vm.search,"custom-filter":_vm.customFilter,"locale":"fr-CH","no-data-text":"Aucun résultat","no-results-text":"Aucun résultat correspondant aux filtres","header-props":{
            'sort-by-text': 'Trier par',
        },"footer-props":{
            'items-per-page-all-text': 'Tous',
            'items-per-page-text': 'Lignes par page',
            'items-per-page-options': [5, 10, 20, 50, -1],
        }},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
        var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getGearType(value).name)+" ")]}},{key:"item.model",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getGearModel(item.type, item.model).name)+" ")]}},{key:"item.variant",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getGearVariant(item.type, item.variant).name)+" ")]}},{key:"item.stockCully",fn:function(ref){
        var item = ref.item;
        var value = ref.value;
return [_c('v-edit-dialog',{attrs:{"transition":"slide-x-transition"},on:{"close":function($event){return _vm.editValue(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Stock Cully","type":"number","rules":[_vm.rules.posNum],"single-line":""},model:{value:(item.stockCully),callback:function ($$v) {_vm.$set(item, "stockCully", $$v)},expression:"item.stockCully"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(value || 0)+" ")])]}},{key:"item.stockForel",fn:function(ref){
        var item = ref.item;
        var value = ref.value;
return [_c('v-edit-dialog',{attrs:{"transition":"slide-x-transition"},on:{"close":function($event){return _vm.editValue(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Stock Forel","type":"number","rules":[_vm.rules.posNum],"single-line":""},model:{value:(item.stockForel),callback:function ($$v) {_vm.$set(item, "stockForel", $$v)},expression:"item.stockForel"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(value || 0)+" ")])]}},{key:"item.id",fn:function(ref){
        var value = ref.value;
return [_c('m-confirm-action',{attrs:{"btnIconDisp":""},on:{"confirmed":function($event){return _vm.deleteItem(value)}}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }