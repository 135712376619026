var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"loading":_vm.loading,"loading-text":"Chargement...","headers":_vm.headers,"items":_vm.gear.using.concat( _vm.gear.old),"items-per-page":20,"locale":"fr-CH","no-data-text":"Aucun résultat","no-results-text":"Aucun résultat correspondant aux filtres","header-props":{
        'sort-by-text': 'Trier par',
    },"footer-props":{
        'items-per-page-all-text': 'Tous',
        'items-per-page-text': 'Lignes par page',
        'items-per-page-options': [5, 10, 20, 50, -1],
    }},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
    var item = ref.item;
    var value = ref.value;
return [(_vm.warning[value] && item.using)?_c('v-tooltip',{attrs:{"bottom":"","color":"warning"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert ")])]}}],null,true)},[_c('span',[_vm._v("Plusieurs items du même type en usage")])]):_vm._e(),_vm._v(" "+_vm._s(_vm.getGearType(value).name)+" ")]}},{key:"item.model",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getGearModel(item.type, item.model).name)+" ")]}},{key:"item.variant",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getGearVariant(item.type, item.variant).name)+" ")]}},{key:"item.using",fn:function(ref){
    var value = ref.value;
return [_c('v-icon',[_vm._v(_vm._s(value ? 'mdi-check' : 'mdi-close'))])]}},{key:"item.id",fn:function(ref){
    var item = ref.item;
return [_c('m-gear-details-view-dialog',{attrs:{"gearItem":item}}),(_vm.canEdit)?_c('m-gear-details-edit-dialog',{attrs:{"type":"switch","icon":"","disabled":!item.using,"gearItem":item,"otherItems":_vm.gear,"uid":_vm.uid}}):_vm._e(),(_vm.canEdit)?_c('m-gear-details-edit-dialog',{attrs:{"type":"edit","icon":"","gearItem":item,"otherItems":_vm.gear,"uid":_vm.uid}}):_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }