<template>
    <v-dialog
        v-model="dialog"
        max-width="400px"
    >
        <template v-slot:activator="{ on, attrs }">
            <slot name="button" v-bind:activator="{ on, attrs }">
                <v-btn
                    :disabled="btnDisabled"
                    :loading="btnLoading"
                    :icon="btnIconDisp"
                    :color="btnColor"
                    :x-small="btnXSmall"
                    :small="btnSmall"
                    :large="btnLarge"
                    :x-large="btnXLarge"
                    v-on="on"
                    v-bind="attrs"
                >
                    <v-icon v-if="btnIconLeft" left>{{ btnIcon }}</v-icon>
                    {{ btnText }}
                    <v-icon v-if="!btnIconLeft" :right="!!btnText">{{ btnIcon }}</v-icon>
                </v-btn>
            </slot>
        </template>

        <v-card>
            <v-card-title>
                {{ cardTitle }}
            </v-card-title>

            <v-card-text>
                <slot>
                    Cette action ne pourra pas être annulée.
                </slot>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="secondary"
                    @click="dialog = false"
                >
                    {{ cardCancelText }}
                </v-btn>

                <v-btn
                    :color="cardConfirmColor"
                    @click="confirmed"
                >
                    <v-icon v-if="cardConfirmLeft" left>{{ cardConfirmIcon }}</v-icon>
                    {{ cardConfirmText }}
                    <v-icon v-if="!cardConfirmLeft" right>{{ cardConfirmIcon }}</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'mConfirmAction',
    props: {
        btnIcon: { type: String, default: 'mdi-trash-can' },
        btnIconDisp: { type: Boolean, default: false },
        btnIconLeft: { type: Boolean, default: false },
        btnColor: { type: String, default: 'error' },
        btnText: { type: String, default: '' },
        btnDisabled: {type: Boolean, default: false},
        btnLoading: {type: Boolean, default: false},
        btnXSmall: { type: Boolean, default: false },
        btnSmall: { type: Boolean, default: false },
        btnLarge: { type: Boolean, default: false },
        btnXLarge: { type: Boolean, default: false },
        cardTitle: { type: String, default: 'Confirmation' },
        cardCancelText: { type: String, default: 'Annuler' },
        cardConfirmText: { type: String, default: 'Supprimer' },
        cardConfirmColor: { type: String, default: 'error' },
        cardConfirmIcon: { type: String, default: 'mdi-trash-can' },
        cardConfirmLeft: { type: Boolean, default: false },
    },
    data: () => ({
        dialog: null,
    }),
    methods: {
        confirmed() {
            this.$emit('confirmed');
            this.dialog = false;
        }
    }
}
</script>

<style>

</style>