<template>
    <v-container>
        <!-- <div>
            <v-row>
                <v-col>
                    <v-btn 
                        block large 
                        color="accent"
                        :to="{ name: 'Gear stock', params: {id: 'forel'} }"
                    >
                        Forel <v-icon right>mdi-forest</v-icon>
                    </v-btn>
                </v-col>
                <v-col>
                    <v-btn 
                        block large 
                        color="accent"
                        :to="{ name: 'Gear stock', params: {id: 'cully'} }"
                    >
                        Cully
                        <v-icon right>mdi-fruit-grapes</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </div> -->

        <v-row justify="space-between" class="mb-2">
            <v-col cols="12" sm="6" md="4"
            class="pt-3 pb-0 pb-sm-3">
                <v-text-field
                    label="Rechercher"
                    v-model="search"
                    hint="Chercher par nom ou NIP"
                    clearable
                    prepend-icon="mdi-magnify"
                />
            </v-col>
            <v-col cols="12" sm="6" md="4"
            class="pt-3 pb-0 pb-sm-3">
                <v-select
                    label="Détachement"
                    v-model="filterSection"
                    :items="['DAP', 'DPS']"
                    clearable
                    prepend-icon="mdi-filter"
                />
            </v-col>
        </v-row>
        <v-data-table
            :loading="loading"
            loading-text="Chargement..."
            :headers="headers"
            :items="users"
            sort-by="shortName"
            :items-per-page="20"
            :search="search"
            :custom-filter="customFilter"
            locale="fr-CH"
            no-data-text="Aucun résultat"
            no-results-text="Aucun résultat correspondant aux filtres"
            :header-props="{
                'sort-by-text': 'Trier par',
            }"
            :footer-props="{
                'items-per-page-all-text': 'Tous',
                'items-per-page-text': 'Lignes par page',
                'items-per-page-options': [5, 10, 20, 50, -1],
            }"
            @click:row="rowClick"
        >
            <template #[`item.rank`]="{value}">
                {{ getRank(value)['med'] }}.
            </template>

            <template #[`item.gear.hood`]="{value}">
                <v-icon v-if="value">mdi-check</v-icon>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import { RANKS, SORT_RANKS } from '@/constants';
import { collection, onSnapshot, getFirestore, query, getDocs, where } from "firebase/firestore";
const db = getFirestore();
let dbUnsub1;

export default {
    name: 'mGear',
    data() { return {
        users: [],
        loading: 'accent',
        search: '',
        filterSection: 'DPS',
    }},
    computed: {
        headers() { return [
            { text: 'Grade', value: 'rank', 
                sort: (a, b) => SORT_RANKS(a, b)
            },
            { text: 'Nom', value: 'shortName', },
            // { text: 'NIP', value: 'nip', },
            { text: 'Détach.', value: 'section', filter: val => !this.filterSection || val === this.filterSection },
            { text: 'V. feu', value: 'gear.fireJacket', align: 'center', },
            { text: 'P. feu', value: 'gear.firePants', align: 'center', },
            { text: 'G. feu', value: 'gear.fireGloves', align: 'center', },
            { text: 'G. cuir', value: 'gear.workGloves', align: 'center', },
            { text: 'Bottes', value: 'gear.boots', align: 'center', },
            { text: 'Cagoule', value: 'gear.hood', align: 'center', },
        ]},
    },
    methods: {
        getRank(rankId) {
            if (RANKS[rankId])
                return RANKS[rankId];
        },
        rowClick(row) {
            this.$router.push({ name: 'Gear details', params: {id: row.uid} })
        },
        customFilter(value, search, item) {
            value;
            return !search 
                || item.nip.toLowerCase().includes(search.toLowerCase())
                || item.shortName.toLowerCase().includes(search.toLowerCase());
        },
    },
    mounted() {
        const q1 = query(collection(db, 'users'), where('status', '==', 'active'));
        dbUnsub1 = onSnapshot(q1, snapshot1 => {
            const gearTypes = ['fireJacket', 'firePants', 'fireGloves', 'workGloves', 'boots', 'hood'];
            let dbUsers = [];
            let promises = [];
            let i = 0;

            // for every user
            snapshot1.forEach(doc1 => {
                let data = {
                    nip: doc1.get('nip'),
                    rank: doc1.get('rank'),
                    name: doc1.get('name'),
                    shortName: '',
                    contact: doc1.get('contact'),
                    section: doc1.get('section'),
                    status: doc1.get('status'),
                    gear: {},
                    actions: {},
                    uid: '',
                };
                
                gearTypes.forEach(type => data.gear[type] = '');

                data.shortName = `${doc1.get('name.last')} ${doc1.get('name.first')}`;
                const mobile = doc1.get('contact.phone.mobile') || '';
                
                data.actions = {
                    mobile: mobile,
                    email:  doc1.data().contact.email[0],
                    uid:    doc1.id,
                };

                data.uid = doc1.id;

                const q2 = query(collection(db, 'users', doc1.id, 'gear'), where('using', '==', true));
                promises[i] = getDocs(q2).then((snapshot2) => {
                    snapshot2.forEach(doc2 => {
                        const type = doc2.get('type');
                        if (gearTypes.includes(type))
                            data.gear[type] = doc2.get('size') || true;
                    });
                }).catch(error => {
                    this.$store.commit('setError', { type: 'firebase-firestore', code: error.code, message: error.message });
                });
                dbUsers.push(data);
                i++;
            });

            if ( Promise.all(promises) && promises.length != 1 ) {
                this.users = dbUsers;
                this.loading = false;
            }
        }, error => {
            this.$store.commit('setError', { type: 'firebase-firestore', code: error.code, message: error.message });
        });
    },
    destroyed() {
        if (dbUnsub1)
            dbUnsub1();
    }
}
</script>

<style>

</style>