<template>
    <v-container>
        <v-row class="mb-1">
            <v-col>
                <v-btn color="secondary"
                    :to="{name: 'Gear'}"
                    small
                >
                    <v-icon :left="big">mdi-format-list-bulleted-square</v-icon>
                    <span v-if="big">Liste</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-card flat>
            <v-card-title>
                {{ user.name.first }}
                {{ user.name.last }}
            </v-card-title>

            <v-card-subtitle class="mt-n2">
                <v-chip class="mr-1">NIP: {{ user.nip }}</v-chip>
                <v-chip v-if="!!user.section" v-text="user.section" class="mr-1"/>
                <v-chip v-if="!!user.status" v-text="statuses[user.status]"/>
            </v-card-subtitle>

            <v-divider/>

            <v-card-text>
                <v-row justify="space-between">
                    <v-col cols="12" sm="6" md="4" lg="3">
                        <span :class="`text-h6 ${$vuetify.theme.dark ? 'white' : 'black'}--text`">Equipement</span>
                        <br>
                        Trié par usage puis date de distribution/récupération selon usage 
                    </v-col>
                    <v-col cols="12" sm="4" md="3" lg="2">
                        <v-select
                            label="Filtrer par utilisation"
                            v-model="usingFilter"
                            :items="[
                                {text: 'En usage', value: true},
                                {text: 'Anciens', value: false},
                                {text: 'Tous', value: null}, 
                            ]"
                            outlined
                            dense
                            hide-details
                            class="mb-2"
                        />
                        <m-gear-details-edit-dialog
                        v-if="canEdit"
                        type="new" 
                        :otherItems="gear" :uid="user.uid"
                        color="primary" block/>
                    </v-col>
                </v-row>
                <m-gear-data-table 
                    :gear="gear"
                    :loading="loading.gear"
                    :warning="warning"
                    :usingFilter="usingFilter"
                    :uid="user.uid"
                />
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { GEAR_TYPES } from '@/constants/gear';

import mGearDataTable from '../components/gearDetails/dataTable.vue';
import mGearDetailsEditDialog from '../components/gearDetails/editDialog.vue';

import { collection, doc, getFirestore, onSnapshot } from "firebase/firestore";
const db = getFirestore();
let dbUnsub1, dbUnsub2;

export default {
    components: { mGearDataTable, mGearDetailsEditDialog },
    data: () => ({
        loading: {user: 'accent', gear: 'accent'},
        user: new window.UserProfile(),
        gear: { using: [], old: [] },
        warning: {},
        usingFilter: true,
        statuses: {
            active: 'Actif', old: 'Ancien'
        },
    }),
    computed: {
        canEdit() {
            return this.$store.getters.canAccess('gear_admin', 'gear_mod');
        },
        big() {
            return !this.$vuetify.breakpoint.xsOnly;
        },
    },
    mounted() {
        const id = this.$route.params.id;
        dbUnsub1 = onSnapshot(doc(db, 'users', id), doc => {
            if (!doc.exists()) {
                return;
            }
            let data = {
                uid: id,
                nip: doc.get('nip'),
                rank: doc.get('rank'),
                name: doc.get('name'),
                contact: doc.get('contact'),
                section: doc.get('section'),
                status: doc.get('status'),
            };

            this.user.updateData(data);
            this.loading.user = false;
        }, error => {
            this.$store.commit('setError', { type: 'firebase-firestore', code: error.code, message: error.message });
        });

        dbUnsub2 = onSnapshot(collection(db, 'users', id, 'gear'), snap => {
            let count = {};
            let dbGear = { using: [], old: [] };

            snap.forEach(doc => {
                let dbData = doc.data();
                dbData.id = doc.id;
                dbData.lastDate = dbData.using ? dbData.outDate : dbData.inDate;

                const status = dbData.using === true ? 'using' : 'old';
                dbGear[status].push(dbData);

                // VERIFY IF 2 SAME TYPES HAVE USING
                if (status === 'using') {
                    // eslint-disable-next-line no-prototype-builtins
                    if (!count.hasOwnProperty(dbData.type))
                        count[dbData.type] = 0;
                    let danger = false;
                    if (++count[dbData.type] > 1)
                        danger = true
                    this.$set(this.warning, dbData.type, danger);
                }
            });

            ['using', 'old'].forEach(type => {
                dbGear[type].sort((a, b) => {
                    const textA = GEAR_TYPES[a.type].name.toLowerCase();
                    const textB = GEAR_TYPES[b.type].name.toLowerCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                dbGear[type].sort((a, b) => new Date(b.lastDate) - new Date(a.lastDate));
            });
            this.gear = dbGear;
            this.loading.gear = false;
            
        }, error => {
            this.$store.commit('setError', { type: 'firebase-firestore', code: error.code, message: error.message });
            this.loading.gear = false;
        });
    },
    destroyed() {
        if (dbUnsub1) dbUnsub1();
        if (dbUnsub2) dbUnsub2();
    }
}
</script>

<style>

</style>