<template>
    <v-dialog
        v-model="dialog"
        max-width="500px"
        persistent
        @input="dialogChange"
    >
        <template #activator="{ on, attrs }">
            <v-btn
                :disabled="disabled"
                :color="color" :block="block"
                v-bind="attrs" v-on="on"
            >
                <v-icon>mdi-plus</v-icon>
                Nouveau
            </v-btn>
        </template>
        <v-card>
            <v-form
                ref="dialogForm"
                @submit="updateGear"
                @submit.prevent
            >
                <v-card-title>
                    Nouvel item
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-select
                                v-model="form.type"
                                label="Type"
                                outlined
                                :rules="[rules.req]"
                                :items="getTypes"
                                item-text="name"
                                @input="$set(form, 'model', '')"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-select
                                v-model="form.model"
                                label="Modèle"
                                outlined
                                :rules="[rules.req]"
                                :items="getModels"
                                :disabled="!form.type"
                                item-text="name"
                                no-data-text="Pas de modèles disponibles 😅"
                            />
                        </v-col>
                        <v-col cols="12" v-if="getType.hasVariants">
                            <v-select
                                v-model="form.variant"
                                label="Variante"
                                outlined
                                :rules="[rules.req]"
                                :items="getVariants"
                                :disabled="!form.type"
                                item-text="name"
                                no-data-text="Pas de variantes disponibles 😅"
                            />
                        </v-col>
                        <v-col cols="12" v-if="getType.hasSize">
                            <v-text-field
                                v-model="form.size"
                                label="Taille"
                                outlined
                                :rules="[rules.req, rules.size]"
                                :disabled="!form.model"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                v-model="form.remarks"
                                label="Remarques (optionnel)"
                                outlined
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="form.stockCully"
                                label="Stock Cully"
                                type="number"
                                :rules="[rules.posNum]"
                                outlined
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="form.stockForel"
                                label="Stock Forel"
                                type="number"
                                :rules="[rules.posNum]"
                                outlined
                            />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer />

                    <v-btn color="secondary" @click="cancelDialog()">
                        <span v-if="big">Annuler</span>
                        <v-icon :right="big">mdi-close</v-icon>
                    </v-btn>

                    <v-btn
                        type="submit"
                        :color="button[btnStatus].color"
                        :loading="sendLoading"
                        :disabled="sendLoading"
                    >
                        <span v-if="big">{{ button[btnStatus].text }}</span>
                        <v-icon :right="big">{{ button[btnStatus].icon }}</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import { GEAR_TYPES } from '@/constants/gear';
import { REQUIRED } from '@/constants/rules';
import { addDoc, collection, getFirestore } from "firebase/firestore";
const fs = getFirestore();

export default {
    name: 'mGearStockEditDialog',
    props: {
        gearItem: {type: Object, default: () => ({})},
        disabled: Boolean,
        color: {type: String, default: 'accent'},
        block: {type: Boolean, default: false},
    },
    data() { return {
        dialog: false,
        form: {},
        formMeta: {},
        sendLoading: false,
        usersLoading: false,
        btnStatus: 'default',
        button: {
            default: { color: 'primary', icon: 'mdi-content-save', text: 'Enregistrer' },
            success: { color: 'success', icon: 'mdi-check', text: 'Enregistré' },
            failure: { color: 'error', icon: 'mdi-alert', text: 'Echec' },
        },
        rules: {
            req: val => REQUIRED(val),
            size: value => /^[A-Z0-9]*$/.test(value) || 'Format invalide (chiffres et majuscules sans espaces)',
            posNum: value => !value || (!isNaN(value) && value >= 0) || 'Doit être un chiffre positif',
        },
        infraMatUsers: [],
    }},
    computed: {
        getTypes() {
            return Object.values(GEAR_TYPES);
        },
        getType() {
            const type = this.form.type;
            return GEAR_TYPES[type] || {};
        },
        getModels() {
            const type = this.form.type;
            return Object.values(GEAR_TYPES[type]?.models || {});
        },
        getVariants() {
            const typeObj = this.getType;
            return typeObj.hasVariants ? Object.values(typeObj.variants || {}) : [];
        },
        big() {
            return !this.$vuetify.breakpoint.xsOnly;
        },
    },
    created() {
    },
    methods: {
        cancelDialog() {
            this.dialog = false;
            this.$refs.dialogForm.reset();
        },
        dialogChange() {
        },
        updateGear() {
            if (this.$refs.dialogForm.validate()) {
                const formData = this.form;
                formData.stockCully = Number(formData.stockCully) || 0;
                formData.stockForel = Number(formData.stockForel) || 0;

                this.sendLoading = true;
                addDoc(collection(fs, 'gear-stock'), formData).then(() => {
                    this.$store.commit('setSnackbar', { text: 'Item Enregistré', color: 'success', icon: 'mdi-check' });
                    this.dialog = false;
                    this.$refs.dialogForm.reset();
                }).catch(error => {
                    this.btnStatus = 'failure';
                    this.$store.commit('setError', { type: 'firebase-firestore', code: error.code, message: error.message });
                }).finally(() => {
                    this.sendLoading = false;
                });
            }
        },
    },
}
</script>

<style>

</style>