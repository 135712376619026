<template>
    <v-dialog
        v-model="dialog"
        max-width="500px"
        persistent
        @input="dialogChange"
    >
        <template #activator="{ on, attrs }">
            <v-btn v-if="type == 'new'"
                :icon="icon" :disabled="disabled"
                :color="color" :block="block"
                v-bind="attrs" v-on="on"
            >
                <v-icon :left="!icon">mdi-plus</v-icon>
                <span v-if="!icon">Nouveau</span>
            </v-btn>
            <v-btn v-if="type == 'edit'"
                :icon="icon" :disabled="disabled"
                :color="color" :block="block"
                v-bind="attrs" v-on="on"
            >
                <v-icon :left="!icon">mdi-pencil</v-icon>
                <span v-if="!icon">Modifier</span>
            </v-btn>
            <v-btn v-if="type == 'switch'"
                :icon="icon" :disabled="disabled"
                :color="color" :block="block"
                v-bind="attrs" v-on="on"
            >
                <v-icon :left="!icon" :large="icon">mdi-swap-horizontal</v-icon>
                <span v-if="!icon">Echanger</span>
            </v-btn>
        </template>
        <v-card>
            <v-form
                ref="dialogForm"
                @submit="updateGear"
                @submit.prevent
            >
                <v-card-title>
                    {{ type == 'edit' ? 'Modifier item' : 'Nouvel item' }}
                </v-card-title>

                <v-card-text>
                    <!-- GENERAL -->
                    <p class="text-subtitle-1">Informations</p>
                    <v-select
                        v-model="form.type"
                        label="Type"
                        outlined
                        :rules="[rules.req]"
                        :items="getTypes"
                        item-text="name"
                        @input="$set(form, 'model', '')"
                    />
                    <v-select
                        v-model="form.model"
                        label="Modèle"
                        outlined
                        :rules="[rules.req]"
                        :items="getModels"
                        :disabled="!form.type"
                        item-text="name"
                        no-data-text="Pas de modèles disponibles 😅"
                    />
                    <v-select
                        v-if="getType.hasVariants"
                        v-model="form.variant"
                        label="Variante"
                        outlined
                        :rules="[rules.req]"
                        :items="getVariants"
                        :disabled="!form.type"
                        item-text="name"
                        no-data-text="Pas de variantes disponibles 😅"
                    />
                    <v-text-field
                        v-if="getType.hasSize"
                        v-model="form.size"
                        label="Taille"
                        outlined
                        :rules="[rules.req, rules.size]"
                        :disabled="!form.model"
                    />
                    <v-text-field
                        v-model="form.ecaid"
                        label="Identifiant ECA (optionnel)"
                        outlined
                    />
                    <v-text-field
                        v-model="form.remarks"
                        label="Remarques (optionnel)"
                        hint="Raison d'échange, particularités, etc."
                        outlined
                    />

                    <!-- HISTORIQUE -->
                    <v-divider class="my-2"/>
                    <p class="text-subtitle-1">Historique</p>
                    <v-text-field
                        v-model="form.outDate"
                        type="date"
                        label="Date de distribution"
                        outlined
                        :rules="[rules.req, rules.date]"
                    />
                    <v-select
                        v-model="form.outUser"
                        label="Distrubé par"
                        outlined
                        :rules="[rules.req]"
                        :items="infraMatUsers"
                        :loading="usersLoading"
                        item-value="uid"
                        item-text="shortName"
                        no-data-text="Personne au pompiers 😅"
                    />

                    <v-checkbox
                        v-model="form.using"
                        label="En usage"
                    />

                    <div v-if="!form.using">
                        <v-text-field
                            v-model="form.inDate"
                            type="date"
                            label="Date de récupération"
                            outlined
                            :rules="[rules.req, rules.date]"
                        />
                        <v-select
                            v-model="form.inUser"
                            label="Récupéré par"
                            outlined
                            :rules="[rules.req]"
                            :items="infraMatUsers"
                            :loading="usersLoading"
                            item-value="uid"
                            item-text="shortName"
                            no-data-text="Personne au pompiers 😅"
                        />
                    </div>

                    <!-- REMPLACEMENT -->
                    <v-divider class="my-2"/>
                    <span class="text-subtitle-1">Remplacement</span>
                    <v-checkbox
                        v-model="formMeta.rep"
                        label="Remplace un autre item"
                    />
                    <v-select
                        v-if="formMeta.rep"
                        v-model="form.replaces"
                        outlined
                        label="Ancien item"
                        :rules="[rules.req]"
                        :items="[...otherItems.using, ...otherItems.old]"
                        :item-text="item => replacementSelectText(item)"
                        item-value="id"
                        no-data-text="Pas d'items disponibles"
                    />
                    <em v-if="type === 'switch'">
                        L'item remplacé sera marqué comme récupéré par la personne à la date que le nouvel item a été distribué.
                        L'item passera en mode 'ancien'.
                    </em>
                </v-card-text>

                <v-card-actions>
                    <m-confirm-action
                        v-if="type === 'edit' && canDelete"
                        :btnDisabled="sendLoading"
                        :btnLoading="sendLoading"
                        :btnText="big ? 'Supprimer' : ''"
                        @confirmed="deleteItem()"
                    >
                        La suppression d'un item est définitive et l'action ne pourra pas être annulée.
                        Il est recommandé d'indiquer que l'item a été rendu.
                    </m-confirm-action>

                    <v-spacer />

                    <v-btn color="secondary" @click="cancelDialog()">
                        <span v-if="big">Annuler</span>
                        <v-icon :right="big">mdi-close</v-icon>
                    </v-btn>

                    <v-btn
                        type="submit"
                        :color="button[btnStatus].color"
                        :loading="sendLoading"
                        :disabled="sendLoading"
                    >
                        <span v-if="big">{{ button[btnStatus].text }}</span>
                        <v-icon :right="big">{{ button[btnStatus].icon }}</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import { GEAR_TYPES } from '@/constants/gear';
import { REQUIRED, DATE } from '@/constants/rules';
import mConfirmAction from '../app/confirmAction.vue';
import { addDoc, collection, deleteDoc, doc, getDocs, getFirestore, orderBy, query, setDoc, updateDoc } from "firebase/firestore";
const db = getFirestore();

export default {
    name: 'mGearDetailsEditDialog',
    components: {
        mConfirmAction
    },
    props: {
        type: {type: String, required: true },
        gearItem: {type: Object, default: () => ({})},
        otherItems: {type: Object, default: () => ({})},
        icon: Boolean,
        disabled: Boolean,
        color : {type: String, default: ''},
        block : {type: Boolean, default: false},
        uid: {type: [String], required: true},
    },
    data() { return {
        dialog: false,
        form: {},
        formMeta: {},
        sendLoading: false,
        usersLoading: false,
        btnStatus: 'default',
        button: {
            default: { color: 'primary', icon: 'mdi-content-save', text: 'Enregistrer' },
            success: { color: 'success', icon: 'mdi-check', text: 'Enregistré' },
            failure: { color: 'error', icon: 'mdi-alert', text: 'Echec' },
        },
        rules: {
            req: val => REQUIRED(val),
            size: value => /^[A-Z0-9]*$/.test(value) || 'Format invalide (chiffres et majuscules sans espaces)',
            date: value => DATE(value) || 'Format invalide (AAAA-MM-JJ)',
        },
        infraMatUsers: [],
    }},
    computed: {
        getTypes() {
            return Object.values(GEAR_TYPES);
        },
        getType() {
            const type = this.form.type;
            return GEAR_TYPES[type] || {};
        },
        getModels() {
            const type = this.form.type;
            return Object.values(GEAR_TYPES[type]?.models || {});
        },
        getVariants() {
            const typeObj = this.getType;
            return typeObj.hasVariants ? Object.values(typeObj.variants || {}) : [];
        },
        canDelete() {
            return this.$store.getters.canAccess('gear_admin');
        },
        big() {
            return !this.$vuetify.breakpoint.xsOnly;
        },
    },
    created() {
        this.resetForm();
    },
    methods: {
        resetForm() {
            this.formMeta = { rep: false };
            const uid = this.$store.state.user.data.uid;
            
            switch (this.type) {
                case 'edit': 
                    this.form = Object.assign({}, this.gearItem); //copy without reference
                    this.formMeta.rep = !!this.gearItem.replaces;
                    break;
                case 'switch':
                    this.form = {
                        outDate: new Date().toISOString().split('T')[0],
                        outUser: uid,
                        using: true,
                        type: this.gearItem.type,
                        replaces: this.gearItem.id,
                        in: {}, out: {},
                    };
                    this.formMeta.rep = true;
                    break;
                default:
                    this.form = {
                        outDate: new Date().toISOString().split('T')[0],
                        outUser: uid,
                        using: true
                    };
            }
        },
        cancelDialog() {
            this.dialog = false;
            this.$refs.dialogForm.resetValidation()
            this.resetForm();
        },
        dialogChange(e) {
            if (e) {
                this.usersLoading = 'accent';
                this.infraMatUsers = [];
                let dbUsers = {mat: [], all: []};
                const q = query(collection(db, 'users'), orderBy('name.last'));
                getDocs(q).then(snap => {
                    snap.forEach(doc => {
                        const key = doc.get('roles.inframat') ? 'mat' : 'all';
                        dbUsers[key].push({
                            uid: doc.id,
                            shortName: `${doc.get('name.last')} ${doc.get('name.first').charAt(0)}.`
                        });
                    });
                    this.infraMatUsers = [
                        {header: 'Equipe mat'},
                        ...dbUsers.mat, 
                        {divider: true}, 
                        {header: 'Autres membres'}, 
                        ...dbUsers.all
                    ];
                }).finally(() => {
                    this.usersLoading = false;
                });
            }
        },
        replacementSelectText(item) {
            const type = GEAR_TYPES[item.type].name || item.type;
            const model = GEAR_TYPES[item.type].models[item.model].name || item.model;
            const size = item.size ? ` ${item.size}` : '';
            const inout = item.using ? 'out' : 'in';
            const date = new Date(item[inout+'Date']).toLocaleDateString('fr-CH');
            const using = item.using ? 'En usage' : 'Ancien';
            return `${type} ${model}${size}, ${using} depuis ${date}`
        },
        deleteItem() {
            this.sendLoading = true;
            const uid = this.uid;
            const oldId = this.gearItem.id;
            if (uid && oldId) {
                deleteDoc(doc(db, 'users', uid, 'gear', oldId)).then(() => {
                    this.$store.commit('setSnackbar', { text: 'Item supprimé', color: 'success' });
                    this.dialog = false;
                }).catch(error => {
                    this.$store.commit('setError', { type: 'firebase-firestore', code: error.code, message: error.message });
                }).finally(() => {
                    this.sendLoading = false;
                });
            }
        },
        updateGear() {
            const uid = this.uid;
            const type = this.type;
            const oldId = this.gearItem.id;
            const replaces = this.formMeta.rep;
            const formData = Object.assign({}, this.form); //copy without reference
            let promises = [];

            if (formData.using){
                delete formData.inUser;
                delete formData.inDate;
            }
            if (!this.getType.hasVariants)
                formData.variant = null;
            if (!this.getType.hasSize)
                formData.size = null;
            delete formData.id;

            if (this.$refs.dialogForm.validate() && uid != '') {
                this.sendLoading = true;
                if (type === 'edit' && oldId) {
                    promises[0] = setDoc(doc(db, 'users', uid, 'gear', oldId), formData);
                } else if (type === 'switch' || type === 'new') {
                    promises[1] = addDoc(collection(db, 'users', uid, 'gear'), formData);
                    if (type === 'switch' && oldId && replaces) {
                        promises[2] = updateDoc(doc(db, 'users', uid, 'gear', formData.replaces), {
                            using: false,
                            'inDate': formData.outDate,
                            'inUser': formData.outUser,
                        });
                    }
                }

                Promise.all(promises).then(() => {
                    this.$store.commit('setSnackbar', { text: 'Item Enregistré', color: 'success', icon: 'mdi-check' });
                    this.dialog = false;
                    this.resetForm();
                }).catch(error => {
                    this.btnStatus = 'failure';
                    this.$store.commit('setError', { type: 'firebase-firestore', code: error.code, message: error.message });
                }).finally(() => {
                    this.sendLoading = false;
                });
            }
        },
    },
}
</script>

<style>

</style>